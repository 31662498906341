@import url(uplot/dist/uPlot.min.css);

@import url(../css/icons.css);

* {
    margin: 0;
    padding: 0;
}

:root {
  --color-dark-gray: #A3A3A3;
  --color-gold: #FFD700;
  --color-dark-purple: #6c5ce7;
  --color-light-purple: #a29bfe;
  --color-dark-blue: #0984e3;
  --color-light-blue: #74b9ff;

  --theme-color-dark: #3B3738;
  --theme-color-light: #EBEBEB;

  --border-radius: 1px;

  /* Light theme */
  --color-purple: var(--color-light-purple);
  --color-blue: var(--color-light-blue);
  --color-blue-inverted: var(--color-dark-blue);
  --background-color: var(--theme-color-light);
  --text-decoration-color: var(--theme-color-dark);
  --text-color: #000;
  --text-color-inverted: #FFF;
}

body {
  background: #212021;
  color: #FFF;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-purple: var(--color-dark-purple);
    --color-blue: var(--color-dark-blue);
    --color-blue-inverted: var(--color-light-blue);
    --background-color: var(--theme-color-dark);
    --text-decoration-color: var(--theme-color-light);
    --text-color: #FFF;
    --text-color-inverted: #000;
  }

  body {
    background: #1c1b1c;
    color: #FFF;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 300;
  min-width: 800px;
}

/* Page layout */
html, body {
    height: 100%;
}

a {
  text-decoration: none;
  color: var(--color-blue);
}

a:hover {
  color: var(--background-color);
  cursor: pointer;
}

#push {
  display: none;
  position: relative;
  min-height: 100%;
}

strong {
    font-weight: 700;
}

/* Logo */
.logo-text {
  letter-spacing: -3px;
}

/* Header */
header {
  overflow: auto;
  padding: 20px;
}

header .column-left {
  float: left;
}

header .column-right {
  float: right;
}

header .logo-image {
  --fixed-logo-image-size: 36px;
  width: var(--fixed-logo-image-size);
  height: var(--fixed-logo-image-size);
  margin-right: 5px;
  display: inline-block;
}

header .logo-text {
  font-size: 48px;
  margin: -6px 0;
  display: inline-block;
}

header .logo-status {
  font-size: 21px;
}

header .header-button {
  color: var(--text-color);
  width: 83px;
  height: 83px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  margin-left: -5px;
}

header .header-button > span:first-of-type {
  display: block;
  margin-top: 10px;
  font-size: 22px;
}

header .header-button-group {
  display: inline-block;
}

header .header-button-group:first-of-type {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

header .header-button-group:last-of-type {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

header .header-button-single {
  display: none;
  cursor: pointer;
  border-radius: var(--border-radius);
  margin-right: 20px;
}

header .header-button-single:hover {
  background: var(--background-color) !important;
}

/* Footer */
footer {
  display: none;
  background: var(--background-color);
  color: var(--text-color);
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  padding: 10px 0;
  text-align: center;
  width: 90%;
  margin: 15px auto 0 auto;
}

footer a {
  color: var(--color-blue-inverted);
}

footer a:hover {
  color: var(--text-color);
}

/* Status overly */
#status-overlay {
  padding: 20px 0;
  background: var(--background-color);
  color: var(--text-color);
  border-radius: var(--border-radius);
  text-align: center;
  height: 150px;
  width: 350px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -95px;
  margin-left: -175px;
}

#status-overlay .logo-image {
  --fixed-logo-image-size: 72px;
  width: var(--fixed-logo-image-size);
  height: var(--fixed-logo-image-size);
}

/* Floating tooltip */
#tooltip {
  display: none;
  position: absolute;
  padding: 5px 8px;
  border-radius: var(--border-radius);
  background: var(--background-color);
  color: var(--text-color);
  z-index: 10000;
}

/* Server listing */
#server-list {
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.server {
  padding: 5px 10px;
  margin: 0 5px;
  width: 800px;
  display: inline-block;
}

.server .column {
  float: left;
}

.server .column-favicon {
  width: 80px;
}

.server .column-favicon .server-favicon {
  --fixed-server-favicon-size: 64px;
  width: var(--fixed-server-favicon-size);
  height: var(--fixed-server-favicon-size);
  border-radius: var(--border-radius);
  margin-top: 5px;
}

.server .column-favicon .server-rank {
  display: block;
  width: 64px;
  text-align: center;
}

.server .column-status {
  width: 282px;
}

.server .column-status .server-name {
  display: inline-block;
}

.server .column-status .server-error {
  display: none;
  color: #e74c3c;
}

.server .column-status .server-label {
  color: var(--color-dark-gray);
  font-size: 16px;
  display: none;
}

.server .column-status .server-value {
  color: var(--color-dark-gray);
  font-size: 16px;
}

.server .column-graph {
  height: 100px;
  width: 400px;
}

/* Favorite icons */
.server-is-favorite {
  cursor: pointer;
  color: var(--color-gold);
}

.server-is-favorite:hover::before {
  content: "\f006";
}

.server-is-not-favorite {
  cursor: pointer;
  color: var(--background-color);
}

.server-is-not-favorite:hover {
  color: var(--color-gold);
}

/* Highlighted values */
.server-highlighted-label {
  font-size: 18px;
}

.server-highlighted-value {
  font-size: 18px;
  font-weight: 700;
}

/* Global stats */
.global-stat {
  font-weight: 700;
}

/* Sort by */
#sort-by {
  background: var(--color-purple);
}

/* Settings toggle */
#settings-toggle {
  background: var(--color-blue);
}

/* Historical graph */
#big-graph {
  padding-right: 65px;
}

#big-graph, #big-graph-controls, #big-graph-checkboxes {
    width: 90%;
}

#big-graph-checkboxes > table {
    width: 100%;
}

#big-graph {
  margin: 0 auto;
}

#big-graph-controls {
  margin: 10px auto;
  display: none;
}

.graph-controls-show {
  color: var(--text-color);
}

#big-graph-controls .icon-star {
  color: var(--color-gold);
}

#big-graph-controls-drawer {
  background: var(--background-color);
  color: var(--text-color);
  border-radius: var(--border-radius);
  padding-bottom: 10px;
  overflow: auto;
  display: none;
}

#big-graph-controls-drawer .graph-controls-setall {
  text-align: center;
  display: block;
  margin: 15px 0;
}

#big-graph-checkboxes {
  margin: 15px auto 0 auto;
}

/* Basic elements */
.button {
  background: var(--color-blue);
  border-radius: var(--border-radius);
  font-size: 16px;
  padding: 5px 10px;
}

.button:hover {
  background: var(--text-color);
  color: var(--text-color-inverted);
}

/* Percentage bar */
#perc-bar {
  height: 35px;
  position: relative;
  overflow-x: hidden;
}

#perc-bar .perc-bar-part {
  height: 100%;
  display: inline-block;
  position: absolute;
}

/* Mojang status colors */

/* Light theme */
.mojang-status-online {
  background: #87D37C;
}

.mojang-status-unstable {
  background: #f1c40f;
}

.mojang-status-offline {
  background: #DE5749;
}

@media (prefers-color-scheme: dark) {
  .mojang-status-online {
    background: #66aa5a;
  }

  .mojang-status-unstable {
    background: #cc8a4f;
  }

  .mojang-status-offline {
    background: #A6453B;
  }
}

/* Header rows */
@media only screen and (max-width: 1050px) {
  header {
    padding: 0 !important;
  }

  .header-possible-row-break {
    padding-top: 20px;
    width: 100%;
    text-align: center;
  }

  .header-possible-row-break:last-of-type {
    margin-bottom: 20px;
  }
}

/* uPlot.css overrides */
.uplot .select {
  background: var(--color-blue);
  opacity: 0.3;
}